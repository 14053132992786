@import '../../styles/customMediaQueries.css';

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  /* display: flex;
  flex-direction: column; */
  display: none;
  min-height: 300px;
  height: 67.5vh;
  max-height: 600px;
  padding: 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;

    min-height: 500px;
    height: 70vh;
    max-height: none;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: column;

    max-height: 800px;
    min-height: 600px;
    height: calc(70vh - var(--topbarHeightDesktop));
  }
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 60px;
  }
  @media(min-width:767px){
    padding:0px 40px;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
  @media(max-width:767px){
    padding:0px 10px;
  }
  /* background-color: var(--marketplaceSectionBG); */
}

.section {
  overflow: hidden;
  /* background-color: var(--marketplaceSectionBG); */
  @media(min-width:767px){
    padding:0px 40px;
  }
}
.sectionService {
  overflow: hidden;
  @media(min-width:767px){
    padding:0px 40px;
  }
  /* background-color: #f9f9f9; */
}
.sectionCategory {
  background-color: var(--marketplaceSectionBG);
}
.sectionBottom {
  overflow: hidden;
  /* background-color: var(--marketplaceColor); */
  background-color:var(--matterColorLightBlue);
  /* color: var(--matterColorLight); */
  color:var(--matterColorBlack);
  padding: 20px;
  padding-top: 50px;
}
/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
  /* background-color: yellowgreen; */
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  /* background-color: #f9f9f9; */
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;
  margin-top:0px !important;

  @media (max-width: 767px) {
    margin-top: 28px !important;

  }
  @media(max-width:1024px){
    margin:0px !important;
  }
}
/* .sectionCategory {
  display: flex;
  flex-direction: column;
  margin: 20px;
  background-color: var(--marketplaceSectionBG);
  align-items: center;
} */
.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 48px !important;
  
  @media(max-width:767px) {
    margin-top: 22px !important;
  }
  /* margin-top: 3vh; */
  /* text-align: center; */
  /* background-color: var(--marketplaceSectionBG); */
}

.loadingBody {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: auto;
  min-height: 500px;
}

.loadingImgBody {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: auto;
  @media (min-width: 575px) {
    min-height: 500px;
  }
}

.loadingIcon {
  width: 48px;
  height: 48px;
  margin: auto;
}

.loadingImgIcon {
  width: 100%;
  height: 100%;
}

.bannerImg{
  margin: 0;
  /* padding-top: 1px; */
}
.circleContainer {
  text-align: center;
  margin-top: 35px;
  @media(max-width:767px) {
    margin-top: 25px;
  }
  @media(max-width:1024px){
    padding:0 38px;
  }
  @media(max-width:767px){
    padding:0px !important;
  }
}

.heading {
  font-family: var(--cambriaFont);
  font-size: 30px;
  font-weight: 400;
  line-height: 34.11px;
  letter-spacing: 0.4650000035762787px;
  text-align: center;
  margin-bottom: 25px;
  color:var(--matterColorBlack);
  @media(max-width:1024px) {
    font-size:21px;
    font-weight:400;
    line-height: 24px;
    letter-spacing: 0.3450000035762787px;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1.5rem;
  justify-content: center;
  max-width: 64rem;
  margin: 0 auto;
  padding-bottom: 10px;
  @media(max-width:767px) {
    display: none !important;
  }
  @media(max-width:1024px){
    gap: 0px !important;
  }
}

.mobileGrid{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  justify-content: center;
  max-width: 64rem;
  margin: 0 auto;
  @media(min-width:767px) {
    display: none !important;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  position: relative;
  height: max-content;
  padding-top:10px;
  &:hover{
    animation: shadowPulse 1s infinite ease-in-out;
    transform: scale(1.05);
    border-radius: 10px;
  }
}

@keyframes shadowPulse {
  0% {
      box-shadow: 0px 0px 5px var(--matterColorBoxShadow);
  }
  50% {
      box-shadow: 0px 0px 15px var(--matterColorBoxShadow);
  }
  100% {
      box-shadow: 0px 0px 5px var(--matterColorBoxShadow);
  }
}
.image {
  width: 114px;
  height: 114px;
  & > img{
    width: 100%;
    height:100%;
  object-fit: cover;
  /* background-position: center; */
  border-radius: 50%;
  border: 1px solid #ddd;
  }
  @media(max-width:1024px) {
    width: 91px;
    height: 91px;
  }
}

.label {
  font-size: 16px;
  font-weight:400;
  margin-top: 0.5rem;
  width: 110px;
  text-align: center;
  display: flex;
  align-self: center;
  justify-content: center;
  text-align: center;
  line-height: 20px;
  color:var(--matterColorBlack);
  font-family:var(--marketplaceFontInter);
  &:hover{
    text-decoration: underline;
  }
  @media(max-width:1024px){
    font-size:14px;
    font-weight:400;
    line-height: 14px;
  }
  @media(max-width:767px){
    line-height: 12px;
  }
}
.layoutWrapperMainClass{
  /* @media(min-width:767px){
    padding:0px 40px;
  } */
}
