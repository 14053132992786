.backgroundImg {
  position: relative;
  & > img {
    width: 100%;
    height: 205px;
    object-fit: cover;
    @media (max-width: 992px) {
      height: 250px;
      object-fit: cover;
    }
  }
  @media (min-width: 767px){
    border-radius: 12px;
    & > img {
      border-radius: 12px;
    }
  }
}

.heroComponent {
  position: absolute;
  top: 50%;
  left: 5%;
  width: 580px;
  z-index: 1;
  transform: translate(0%, -50%);
  @media (max-width: 992px) {
    width: 500px;
    margin: 10px;
    left: 7%;
    /* top:45%; */
  }
  @media (max-width: 576px) {
    width: 325px;
    /* text-align:center; */
    /* display: flex;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
  }
}
.herosection {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  height: 740px;
  width: 100%;
}

.herotext {
  font-size: 30px;
  line-height: 34px;
  font-weight: 700;
  color: #fff;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  font-family:var(--marketplaceFontInter);
  @media (max-width: 992px) {
    font-size: 25px;
    line-height: 30px;
  }
  @media (max-width: 576px) {
    font-size: 20px;
    line-height: 24px;
  }
}
.secondText {
  color: #fff !important;
  font-size: 14px;
  margin-bottom: 16px;
  line-height: 20px;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  @media (max-width: 576px) {
    font-size: 11px;
    line-height: 15px;
  }
  @media(max-width:991px){
    margin-bottom: 25px !important;
    font-weight:400;
  }
}

.SectionHero {
  position: relative;
  /* padding:200px 0; */
  margin-top:40px;
  @media(max-width:1024px){
    margin-top:17px !important;
  }
  @media(max-width:767px){
    margin-top: 0px !important;
  }
  @media(min-width:767px){
    padding:0px 40px;
  }
}
/* .browseBtnDiv {
    display: flex;
    justify-content: center;
  } */
.browseBtn {
  padding: 10px 20px;
  width: auto;
  border-radius: 24px !important;
}
.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
  @media (max-width: 767px) {
    display: flex;
    /* height: max-content; */
    align-items: center;
    justify-content: center;
  }
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    height: max-content;
    & > div {
      width: 100%;
      height: 100%;
    }
  }
}
iframe {
  width: 960px;
  height: 615px;
  @media (max-width: 991px) {
    width: 630px;
    height: 407px;
    padding-bottom: 0;
  }
  @media (max-width: 767px) {
    width: 100%;
    /* height: 50%; */
    padding-bottom: 0;
  }
}
